const i18nData = require('../../config/i18n');

let CURRENT_LOCALE = null;
let LOCAL_SWITCH_KEY = 'localeSwitchedManually-' + (new Date().getFullYear());
1
function detectCurrentLocale() {
  if (typeof window !== 'undefined') {
    if (!CURRENT_LOCALE) {
      // Get locale by path
      let urlParts = location.pathname.split('/');
      for (let locale in i18nData.locales) {
        if (urlParts[1] && i18nData.locales[locale].path === urlParts[1]) {
          CURRENT_LOCALE = locale;
        }
      }
    }
  }

  // If there is no match from path will set default locale
  if (!CURRENT_LOCALE) {
    CURRENT_LOCALE = i18nData.default; // Default language
  }

  // Final check if the provided localeId is not defined
  if (!i18nData.locales[CURRENT_LOCALE]) {
    CURRENT_LOCALE = i18nData.default;
  }
}

detectCurrentLocale();

// If the "lang" is the default language, don't create a prefix. Otherwise
// add a "/$path" before the slug (defined in "i18nData")
function localizedSlug(node) {
  return getLocalePrefix(node.lang) + node.uid;
}

// Change current language
function changeLocale(localeId, reload, lastSegmentOfUrl, switchedManually) {
  if (i18nData.locales[localeId] && localeId !== getCurrentLocale()) {
    CURRENT_LOCALE = localeId;

    if (typeof window !== 'undefined' && reload) {
      // Uncomment if you want to save the same url
      // window.location.replace(getLocalePrefix(localeId) + lastSegmentOfUrl);

      if (switchedManually) {
        sessionStorage.setItem(LOCAL_SWITCH_KEY, localeId);
      }

      window.location.replace(getLocalePrefix(localeId));
    }
  }
}

// Get current locale
function getCurrentLocale() {
  return CURRENT_LOCALE;
}

// Return url prefix for specific locale or detect it by URL
function getLocalePrefix(localeId) {
  if (!localeId) {
    localeId = getCurrentLocale();
  }

  if (i18nData.locales[localeId] && i18nData.default !== localeId) {
    return `/${i18nData.locales[localeId].path}/`;
  }

  return '/';
}

// Translate function
function t($text, forceLocale) {
  const locale = forceLocale ? forceLocale : getCurrentLocale();

  if (i18nData.texts[$text] && i18nData.texts[$text][locale]) {
    return i18nData.texts[$text][locale];
  } else {
    if (process.env.NODE_ENV === 'development') {
      if (typeof i18nData.texts[$text] === 'undefined') {
        console.log(`Missing text: ${locale}.${$text}`);
      } else if (typeof i18nData.texts[$text][locale] !== 'undefined') {
        return '$not-translated$';
      }
    }

    return $text;
  }
}

async function langRedirect(currentPageLang) {
  // Browsers only
  if (typeof window === 'undefined') {
    return;
  }

  // Check if the language was switched manually
  const localeSwitched = sessionStorage.getItem(LOCAL_SWITCH_KEY);
  if (localeSwitched) {
    return;
  }

  // Get the user location
  // let rs = await fetch('https://api.ipstack.com/check?access_key=405664b83ffedb2aad4951cce767b98b');
  let rs = await fetch('https://api.ipgeolocation.io/ipgeo?apiKey=67c386cd88cb4653bb2994d88cbbb644');
  let data = await rs.json();

  // Check for country code
  let countryCode = data ? data.country_code || data.country_code2 : '';
  console.log('countryCode', countryCode);
  if (!countryCode) {
    return;
  }


  // Check if the user country is defined in current languages
  let setLocaleId = null;
  Object.entries(i18nData.locales).map(([localeId, localeData]) => {
    if (localeData.countryCodes.indexOf(countryCode) !== -1) {
      setLocaleId = localeId;
    }
  });

  // Redirect
  if (setLocaleId && setLocaleId !== currentPageLang) {
    console.log(`Redirect from [${currentPageLang}] to [${setLocaleId}]`);
    changeLocale(setLocaleId, true);
  }
}

module.exports = {
  getLocalePrefix,
  getCurrentLocale,
  localizedSlug,
  changeLocale,
  t,
  langRedirect
};

exports = module.exports;
