module.exports = {
  default: 'en-gb',
  locales: {
    'en-gb': {
      path: 'en',
      name: 'English',
      label: 'EN',
      countryCodes: ['GB']

    },
    'ja-jp': {
      path: 'jp',
      name: '日本語',
      label: 'JP',
      countryCodes: ['JP']
    }
  },
  texts: require('./i18n-texts.json')
};
