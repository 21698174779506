// Common styles
import './src/styles/normalize.scss';
import './src/styles/variables.scss';
import './src/styles/mixins.scss';
import './src/styles/index.scss';

import { globalHistory } from '@reach/router';
import { changeLocale } from './src/utils/lang-helper';
import i18nData from './config/i18n';

import * as React from 'react';
import { PreviewStoreProvider } from 'gatsby-source-prismic';

export const onInitialClientRender = () => {
  /**
   * This is a workaround for a bug in Gatsby
   *
   * See https://github.com/gatsbyjs/gatsby/issues/8357 for more details
   */
  globalHistory._onTransitionComplete();
};

export const onServiceWorkerUpdateReady = () => window.location.reload(true);

// Detect and set the right locale
function setIskoLocale(location) {
  const loc = location.pathname.split('/');
  for (let locale in i18nData.locales) {
    if (loc[1] && i18nData.locales[locale].path === loc[1]) {
      changeLocale(locale);
    }
  }
}

export const onPreRouteUpdate = ({ location }) => {
  setIskoLocale(location);
};

export const wrapRootElement = ({ element }) => (
  <PreviewStoreProvider>{element}</PreviewStoreProvider>
);
